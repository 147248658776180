import React, { ChangeEvent } from 'react'
import { Button, Form, Input, Checkbox, message, AutoComplete } from 'antd';
import useSetState from '../../hooks/useSetState';
import { styled } from 'styled-components';
import { CloseCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import CopyToClipboard from "react-copy-to-clipboard";
import { channelList, fiatList, getHistoryList, parseURLParams, setHistoryList, spinList } from './config';
import HistoryList from './HistoryList';
import { log } from 'console';

type ParamsProps = { key: string, value: string }[]

const paramsObj = { key: '', value: '' }

export default function GennerUrl() {
  const [state, setState] = useSetState({
    relativePath: true,
    open: false,
    domain: 'bc.game',
    code: '',
    channel: '',
    fiat: '',
    extraSettings: '',
    paramsList: [paramsObj] as ParamsProps,
    urlStr: '',
    enUrlStr: '',
    spin: '',
    isError: false,
    spinPath: '',
    list: [] as string[],
  })
  const [messageApi, contextHolder] = message.useMessage();
  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setState({ [event.target.name]: event.target.value })
  }

  const gennerURL = () => {
    let currList = [...state.paramsList]
    let paramsString = `https://${state.domain}/i-${state.code}-n/`;
    let url = new URL(paramsString)

    if (state.channel) {
      url.searchParams.append('ch', state.channel)
    }
    if (state.fiat) {
      url.searchParams.append('bcn', state.fiat.toLocaleUpperCase() + 'FIAT')
    }

    if (state.extraSettings) {
      url.searchParams.append('p', state.extraSettings)
    }

    currList.map((item) => {
      if (item.key && item.value) return url.searchParams.append(item.key, item.value)
      return item
    })
    let currURL = decodeURIComponent(url.searchParams.toString()) ? `?${decodeURIComponent(url.searchParams.toString())}` : ''
    return processSpin(paramsString, paramsString + currURL)
  }
  const processSpin = (mainStr: string, url: string) => {
    if (!state.relativePath) return url;
    if (!state.spin) return url;
    let str = ''
    if (state.spin !== 'en') {
      str = `/${state.spin}`
    }
    const currUrl = new URL(url);
    const p = currUrl.searchParams.get('p') || ''
    currUrl.searchParams.set('p', str + p)
    return mainStr + '?' + decodeURIComponent(currUrl.searchParams.toString())
  }
  const verifyChange = (value: string) => {
    setState({ isError: false })
  }
  const deleteChange = (index: number) => {
    let currList = [...state.paramsList]
    currList.splice(index, 1)
    setState({ paramsList: currList })
  }
  const addChange = () => {
    let currList = [...state.paramsList]
    currList.push({ key: '', value: '' })
    setState({ paramsList: currList })

  }
  const paramsChange = (index: number, key: string, value: string) => {
    let currList: any = [...state.paramsList]
    currList[index][key] = value
    setState({ paramsList: currList })
  }
  const onSelect = (name: string, data: string) => {
    setState({ [name]: data })
  };

  const saveLocalURL = () => {
    let value = gennerURL()
    let currList = getHistoryList()
    currList.push(value)
    setHistoryList(currList)
    setState({ list: [value] })
  }
  const onParse = (value: string) => {
    const data = parseURLParams(value)
    let currList: ParamsProps = []
    let currObj = { ...data }
    delete currObj.code;
    delete currObj.ch;
    delete currObj.p;
    delete currObj.bcn;
    delete currObj.domainStr;
    currObj && Object.keys(currObj).map((item) => {
      return currList.push({
        key: item,
        value: currObj[item]
      })
    })
    //绝对路径 or 相对路径
    const rePath = !data?.p?.startsWith('http')

    setState({
      code: data.code || '',
      channel: data.ch || '',
      fiat: data?.bcn?.slice(0, -4) || '',
      extraSettings: rePath ? extractPParamValuesFromURL(value).extraSettings : data.p,
      spin: rePath ? extractPParamValuesFromURL(value).spin : '',
      paramsList: currList.length > 0 ? currList : [paramsObj],
      relativePath: rePath,
      domain: data.domainStr,
    })
  }

  const extractPParamValuesFromURL = (url: string) => {
    let extraSettings = ''
    let spin = ''
    const regex = /&p=([^&]+)/; // 正则表达式，匹配&p=后面的非&字符序列
    const match = url.match(regex);
    if (match && match[1]) {
      let str = match[1]
      spin = str.split('/')[1]
      spin = spinList.filter(item => item.value === spin)[0]?.value || ''
      if (!spin) {
        extraSettings = str
      } else {
        extraSettings = '/' + str.split('/').splice(2).join('/')
      }
    }
    return { extraSettings, spin }
  }


  return (
    <FormWrap >
      {contextHolder}
      <Form
        className='form-wrap'
        name="wrap"
        labelCol={{ flex: '130px' }}
        labelAlign="right"
        labelWrap
        wrapperCol={{ flex: '0 1 auto', }}
        colon={false}
        style={{ flexWrap: 'nowrap' }}
      >
        <Form.Item label='Domain:' rules={[{ required: true }]}>
          <Input name="domain" onChange={onChange} value={state.domain} />
        </Form.Item>

        <Form.Item label="Code:" rules={[{ required: true }]}>
          <Input name='code' onChange={onChange} value={state.code} />
        </Form.Item>
        <Form.Item label="Channel:" rules={[]}>
          <AutoComplete
            options={channelList}
            value={state.channel}
            style={{ width: 200 }}
            onChange={(value) => {
              onSelect('channel', value)
            }}
            onBlur={(e) => {
              if (channelList.findIndex(item => item.value === state.channel) < 0) {
                onSelect('channel', '')
              }
            }}
            filterOption={(inputValue, option) =>
              option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
            }
          />
        </Form.Item>
        <Form.Item label="Fiat:" rules={[]}>
          <AutoComplete
            options={fiatList}
            style={{ width: 200 }}
            value={state.fiat}
            onChange={(value) => {
              onSelect('fiat', value)
            }}
            onBlur={() => {
              if (fiatList.findIndex(item => item.value === state.fiat) < 0) {
                onSelect('fiat', '')
              }
            }}
            filterOption={(inputValue, option) =>
              option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
            }
          />
        </Form.Item>
        <Form.Item
          validateStatus={state.isError ? 'error' : 'success'}
          help={!state.isError ? ''
            : state.relativePath
              ? `Required start with '/'`
              : "Required start with 'http'"}
          label="Path:">
          <Input name='extraSettings' value={state.extraSettings} onChange={(e) => {
            let value = e.target.value
            const pattern = /^(h|t|p|http)/;
            if (value === '') {
              setState({ extraSettings: '', isError: false })
            } else if ((state.relativePath && value.startsWith('/')) || (!state.relativePath && pattern.test(value))) {
              setState({ extraSettings: e.target.value, isError: false })
            } else {
              setState({ isError: true })
            }
          }}
            onBlur={(e) => {
              verifyChange(e.target.value)
            }}

            style={{ width: '40%' }} />
          <div style={{ width: '60%', display: 'inline-block' }} >
            <Checkbox checked={state.relativePath} onChange={() => {
              setState({ relativePath: true, extraSettings: '' })
            }}>Relative path</Checkbox>
            <Checkbox checked={!state.relativePath} onChange={() => {
              setState({ relativePath: false, extraSettings: '' })
            }}>Absolute path</Checkbox>
          </div>
        </Form.Item>
        <Form.Item label='Language:' >
          <AutoComplete
            options={spinList}
            value={state.spin}
            style={{ width: 200 }}
            onChange={(value) => {
              onSelect('spin', value)
            }}
            onBlur={() => {
              //只能输入有选项的值
              if (spinList.findIndex(item => item.value === state.spin) < 0) {
                onSelect('spin', '')
              }
            }}
            filterOption={(inputValue, option) =>
              option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
            }
          />
        </Form.Item>
        <Form.Item style={{ flex: '0 1 auto', }} label="Extra parameters:" rules={[]}>
          {state.paramsList.map((item, index) => {
            return (
              <div key={'item' + index} className='pararms'>
                <Input value={item.key} onChange={(e) => {
                  paramsChange(index, 'key', e.target.value)
                }} style={{ width: 200 }} />
                <span style={{ margin: '0 4px' }}>:</span>
                <Input value={item.value} onChange={(e) => {
                  paramsChange(index, 'value', e.target.value)
                }} style={{ width: 200 }} />

                {index === state.paramsList.length - 1 ? <Button
                  style={{ marginLeft: 8 }}
                  type="primary"
                  icon={<PlusCircleOutlined />}
                  onClick={() => addChange()}
                /> :
                  <Button
                    style={{ marginLeft: 8 }}
                    type="primary"
                    icon={<CloseCircleOutlined />}
                    onClick={() => deleteChange(index)}
                  />
                }
              </div>
            )
          })}
        </Form.Item>
        <Form.Item style={{ flexWrap: 'nowrap' }} className='extraWrap' label="URL:">
          <p>{gennerURL()}</p>
          <CopyToClipboard
            text={gennerURL()}
            onCopy={() => {
              messageApi.open({
                type: 'success',
                content: 'Copied',
              });
              saveLocalURL()
            }}
          >
            <Button type="primary" >
              Copy
            </Button>
          </CopyToClipboard>
        </Form.Item>
      </Form>
      <HistoryList onParse={onParse} list={state.list} />
    </FormWrap>
  )
}
const FormWrap = styled.div`
display: flex;
height: 100%;
.form-wrap {
  width: 60%;
  margin-right: 8px;
}
.extraWrap {
  .ant-form-item-control-input-content {
    display: flex;
    align-items: center;
    >p {
      margin-right: 8px;
      text-align: left;
    }
  }
}
.ant-form-item-explain-error {
  text-align: left;
}
.pararms {
  margin-bottom: 8px;
}
@media screen and (max-width: 1260px) {
  flex-direction: column;
  .form-wrap {
    width: 100%;
    margin-right: 0;
  }
}
`