import React from 'react';
import './App.css';
import Login from './pages/login';
import {
  BrowserRouter,
  Route,
  Routes,
} from "react-router-dom";
import Home from './pages/home';
import Kwai from "./pages/kwai";
import LayoutWrap from './layout';
import GennerUrl from './pages/gennerurl';


function App() {
  // const RoutesWithoutUserId = React.memo(() => {

  //   return (
  //     <Routes>
  //       <Route path="/login" Component={Login} />
  //     </Routes>
  //   );
  // });
  return (
    <BrowserRouter>
      <div className="App">
        {/* <RoutesWithoutUserId /> */}
        <LayoutWrap>

          <Routes>
            <Route path="/home" Component={Home} />
            <Route path="/kwai" Component={Kwai} />
            <Route path="/genner_url" Component={GennerUrl} />

          </Routes>
        </LayoutWrap>

      </div>
    </BrowserRouter>
  );
}


export default App;
