export const spinList = [
  { label: 'en', value: 'en' },
  { label: 'vi', value: 'vi' },
  { label: 'id', value: 'id' },
  { label: 'ja', value: 'ja' },
  { label: 'ko', value: 'ko' },
  { label: 'fr', value: 'fr' },
  { label: 'es', value: 'es' },
  { label: 'tl', value: 'tl' },
  { label: 'ar', value: 'ar' },
  { label: 'hi', value: 'hi' },
  { label: 'tr', value: 'tr' },
  { label: 'fa', value: 'fa' },
  { label: 'ru', value: 'ru' },
  { label: 'pt', value: 'pt' },
  { label: 'de', value: 'de' },
  { label: 'fi', value: 'fi' },
  { label: 'th', value: 'th' },
  { label: 'pl', value: 'pl' },
  { label: 'it', value: 'it' },
  { label: 'my', value: 'my' },
  { label: 'ur', value: 'ur' },
  { label: 'uk', value: 'uk' },
  { label: 'ms', value: 'ms' },
  { label: 'bn', value: 'bn' },
  { label: 'mr', value: 'mr' },
  { label: 'ta', value: 'ta' },
  { label: 'te', value: 'te' },
  { label: 'en - IN', value: 'en-IN' },
];
export const fiatList = [
  { label: 'USD', value: 'USD' },
  { label: 'BRL', value: 'BRL' },
  { label: 'INR', value: 'INR' },
  { label: 'EUR', value: 'EUR' },
  { label: 'RUB', value: 'RUB' },
  { label: 'NGN', value: 'NGN' },
  { label: 'IDR', value: 'IDR' },
  { label: 'BDT', value: 'BDT' },
  { label: 'PHP', value: 'PHP' },
  { label: 'VND', value: 'VND' },
  { label: 'THB', value: 'THB' },
  { label: 'KZT', value: 'KZT' },
  { label: 'MAD', value: 'MAD' },
  { label: 'ZAR', value: 'ZAR' },
  { label: 'PLN', value: 'PLN' },
  { label: 'CUP', value: 'CUP' },
  { label: 'KRW', value: 'KRW' },
  { label: 'JPY', value: 'JPY' },
  { label: 'GBP', value: 'GBP' },
  { label: 'HRK', value: 'HRK' },
  { label: 'ISK', value: 'ISK' },
  { label: 'HUF', value: 'HUF' },
  { label: 'NOK', value: 'NOK' },
  { label: 'NZD', value: 'NZD' },
  { label: 'ARS', value: 'ARS' },
  { label: 'MXN', value: 'MXN' },
  { label: 'AUD', value: 'AUD' },
  { label: 'TRY', value: 'TRY' },
  { label: 'IRR', value: 'IRR' },
  { label: 'AED', value: 'AED' },
  { label: 'CAD', value: 'CAD' },
  { label: 'UAH', value: 'UAH' },
  { label: 'CZK', value: 'CZK' },
  { label: 'LKR', value: 'LKR' },
  { label: 'ILS', value: 'ILS' },
  { label: 'EGP', value: 'EGP' },
  { label: 'PKR', value: 'PKR' },
  { label: 'GHS', value: 'GHS' },
  { label: 'VEF', value: 'VEF' },
  { label: 'PEN', value: 'PEN' },
  { label: 'RON', value: 'RON' },
  { label: 'BGN', value: 'BGN' },
  { label: 'RSD', value: 'RSD' },
  { label: 'CLP', value: 'CLP' },
];

export const getHistoryList = () => {
  let list = window.localStorage.getItem('URLLIST') || '[]';
  return JSON.parse(list).reverse();
};
export const setHistoryList = (list: string[]) => {
  window.localStorage.setItem('URLLIST', JSON.stringify(list));
};

export const parseURLParams = (url: string) => {
  const urlObj = new URL(url);
  const regex = /i-(.*?)-n/;
  const match = url.match(regex);
  const code = match ? match[1] : null; //code
  const ch = urlObj.searchParams.get('ch'); //channel
  const bcn = urlObj.searchParams.get('bcn'); //Fiat
  const p = urlObj.searchParams.get('p'); //path
  //Extra parameters
  const queryParams: any = {};
  //域名
  let domainStr = '';
  //正则匹配域名
  const domainRegex = /^(?:https?:\/\/)?(?:www\.)?([^/?]+)/i;
  const domain = url.match(domainRegex);
  if (domain && domain[1]) {
    domainStr = domain[1];
  }
  for (const [key, value] of urlObj.searchParams.entries()) {
    queryParams[key] = value;
  }

  return {
    code,
    ch,
    bcn,
    p,
    domainStr,
    ...queryParams,
  };
};
export const channelList = [{ value: 'meta' }, { value: 'kwai' }, { value: 'taboola' }, { value: 'sharechat' }, { value: 'tiktok' }, { value: 'mjb2' }];
