import React, { useEffect, useRef } from 'react'
import { styled } from 'styled-components'
import useSetState from '../../hooks/useSetState'

export default function Home() {
  const [state, setState] = useSetState({
    time: new Date().getTime(),
  })
  const timeRef = useRef<any>(null)

  useEffect(() => {
    timeRef.current = setInterval(() => {
      setState({ time: new Date().getTime() })
    }, 1000)
    return () => {
      timeRef.current = null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <HomeWrap>
      <span className='time' >{`0${new Date(state.time).getHours()}`.slice(-2)}</span>
      <span>:</span>
      <span className='time'>{`0${new Date(state.time).getMinutes()}`.slice(-2)}</span>
      <span>:</span>
      <span className='time'>{`0${new Date(state.time).getSeconds()}`.slice(-2)}</span>
    </HomeWrap>
  )
}
const HomeWrap = styled.div`
display: flex;
align-items: center;
  span {
    font-size: 180px;
    font-weight: 700;
    color: #000;
    overflow: hidden;
  }
  .time {
    display: inline-block;
    width: 280px;
  
  }
`