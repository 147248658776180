import Search from 'antd/es/input/Search'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { getHistoryList, setHistoryList } from './config';
import { Button } from 'antd';
import { CloseCircleOutlined, FormOutlined } from '@ant-design/icons';

export default function HistoryList({ list, onParse }: {
  list: string[]
  onParse: (value: string) => void
}) {

  const [urlList, setUrlList] = useState<any[]>([])
  useEffect(() => {
    let urlList = [...getHistoryList()]
    setUrlList(urlList)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    if (list.length > 0) {
      let currList = [...list, ...urlList]
      setUrlList(currList)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list])
  const onSearch = (value: string) => {
    onParse(value)
  };
  const deleteChange = (value: string) => {
    let currList = [...urlList]
    let index = currList.findIndex(item => item === value)
    currList.splice(index, 1)
    setUrlList(currList)
    setHistoryList(currList)
  }
  return (
    <DivWrap>
      <p>History</p>
      <Search
        placeholder="URL parse text"
        allowClear
        enterButton="Parse"
        size='middle'
        onSearch={onSearch}
        className='search'
      />
      <div className="list-content">
        {urlList.map((item, index) => {
          return (
            <div className='list-item' key={index}>
              <p >{item}</p>
              <div className='btnWrap' >
                <Button
                  className='btn'
                  type="primary"
                  icon={<FormOutlined />}
                  onClick={() => onSearch(item)}
                />
                <Button
                  className='btn'
                  type="primary"
                  icon={<CloseCircleOutlined />}
                  onClick={() => deleteChange(item)}
                />
              </div>

            </div>
          )
        })}

      </div>
    </DivWrap>
  )
}
const DivWrap = styled.div`
  border: 1px solid #eee;
  border-radius: 4px;
  padding: 8px 0;
  width: 40%;
  height: 100%;
  min-height: 500px;
  .search {
    padding: 0 8px;
    margin-bottom: 8px;
  }
  p {
    text-align: left;
    font-size: 14px;
    margin-bottom: 8px;
    padding: 0 8px;
  }
  .list-content {
    overflow: auto;
    width: 100%;
    height: calc(100% - 80px);

  }
  .list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    border-bottom: 1px solid #eee;
    font-size: 13px;
    .btn {
      opacity: 0;
      margin-left: 8px;
    }
    &:hover {
      background-color: #eee;
    }
    &:hover .btn {
      opacity: 1;
    }
  }
  .btnWrap {
    display: flex;
    flex-wrap: nowrap;
  }
@media screen and (max-width: 1260px) {
  width: 100%;

}


`
