import React, { ReactNode, useState } from 'react';

import { Layout, theme } from 'antd';
import { styled } from 'styled-components';
import SiderComponent from './Sider';
import HeaderComponent from './Header';

const { Content } = Layout;

const LayoutWrap = ({ children }: { children: ReactNode }) => {
  const [collapsed, setCollapsed] = useState(false);

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <LayoutDiv>
      <SiderComponent collapsed={collapsed} />
      <Layout>
        <HeaderComponent
          collapsed={collapsed}
          setCollapsed={setCollapsed}
        />
        <Content
          className='content'
          style={{
            margin: '24px 16px',
            padding: 24,
            minHeight: 280,
            background: colorBgContainer,
          }}
        >
          {children}
        </Content>
      </Layout>
    </LayoutDiv>
  );
};
const LayoutDiv = styled(Layout)`
  height: 100%;
  .header {
    display: flex;
  }
  .content {
    /* display: flex; */
    overflow: auto;
  }
  .ant-menu-title-content {
    text-align: left;
  }
`

export default LayoutWrap;