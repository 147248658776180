import { useEffect } from 'react';

export default function Home() {

  function onPreview () {
    const qs = new URLSearchParams(location.href.split('?')[1]);
    const id = qs.get('pixel_id');
    kwaiq.load(id);
    kwaiq.page();
    kwaiq.instance(id).track('contentView')
    kwaiq.instance(id).track('completeRegistration');
    kwaiq.instance(id).track('purchase', {
      contents: [
        {
          content_type: 'product',
          content_name: 'ftd',
          price: 0,
        },
      ],
    });
  }

  useEffect(() => {
    onPreview();
  }, []);

  return (
    null
  )
}

declare global {
  const kwaiq: any
}
