import {
  UserOutlined,
  HomeOutlined,
  FormOutlined,
  BoxPlotOutlined,
} from '@ant-design/icons';
import { Layout, Menu, Avatar, MenuProps } from 'antd';
import { useNavigate } from 'react-router-dom';
const { Sider, } = Layout;

export default function SiderComponent({ collapsed }: { collapsed: boolean; }) {
  const navigate = useNavigate()
  const routes = [
    {
      key: '/home',
      icon: <HomeOutlined />,
      label: 'Home',
    },
    {
      key: '/genner_url',
      icon: <FormOutlined />,
      label: 'URL Generator',
    },
    {
      key: '/kwai',
      icon: <BoxPlotOutlined />,
      label: 'Kwai Preview',
    },
  ]
  const onClick: MenuProps['onClick'] = (e) => {
    navigate(e.key, { replace: true })

  }
  return (
    <Sider trigger={null} collapsible collapsed={collapsed}>
      <Avatar size={64} icon={<UserOutlined />} />
      <Menu
        onClick={onClick}
        theme="dark"
        mode="inline"
        defaultSelectedKeys={['1']}
        items={routes}
      />
    </Sider>
  )
}
